import { useCallback, useRef, useState } from "react";

import type { MultiFactorResolver } from "@firebase/auth";
import {
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from "@firebase/auth";

import { useAuth } from "@olivahealth/firebase/client";

/**
 * Implemented following the Identity toolkit guide:
 * https://cloud.google.com/identity-platform/docs/web/mfa#signing_users_in_with_a_second_factor
 *
 *
 * @param recaptchaId String HTML id for the element that will contain the Recaptcha verification HTML
 * @returns
 */
export default function useMultiFactorAuthentication(recaptchaId: string) {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const mfaResolver = useRef<MultiFactorResolver | undefined>(undefined);
  const recaptchaVerifier = useRef<RecaptchaVerifier | undefined>(undefined);
  const [verificationId, setVerificationId] = useState<string | null>(null);

  const startMutifactorVerification = useCallback(
    async (error) => {
      setLoading(true);
      mfaResolver.current = getMultiFactorResolver(auth, error);
      const resolver = mfaResolver.current;

      if (recaptchaVerifier.current) {
        recaptchaVerifier.current.clear();
        const element = window.document.getElementById(recaptchaId);
        if (element) {
          element.innerHTML = "";
        }
      }

      // Start the invisible recaptcha instance
      recaptchaVerifier.current = new RecaptchaVerifier(auth, recaptchaId, {
        size: "invisible",
      });

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      // Send SMS verification code.
      const responseVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        },
        recaptchaVerifier.current,
      );

      setLoading(false);
      setVerificationId(responseVerificationId);
    },
    [auth, recaptchaVerifier, recaptchaId],
  );

  const finalizeMultifactorVerification = useCallback(
    async function (verificationCode) {
      if (!mfaResolver.current || !verificationId) {
        throw new Error(
          "Multifactor authentication must be started before calling finalization",
        );
      }
      const resolver = mfaResolver.current;

      // Assertion of the user introduced code
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
        PhoneAuthProvider.credential(verificationId, verificationCode),
      );

      setLoading(false);
      // Complete sign-in. This will also trigger the Auth state listeners.
      await resolver.resolveSignIn(multiFactorAssertion);
    },
    [verificationId],
  );

  return {
    multifactorLoading: loading,
    multifactorStarted: Boolean(verificationId),
    startMutifactorVerification,
    finalizeMultifactorVerification,
  };
}
