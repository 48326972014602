/**
 * Extract the domain from the email address
 * @param email
 */
export function getDomainFromEmail(email: string): string | undefined {
  const emailRegex = /@(.+)$/;
  const match = email.match(emailRegex);

  return match?.[1];
}

/**
 * Check if an email has a sub address
 * A sub-address extends an email (e.g., `user+label@example.com`)
 * so that many different addresses can be used to log in to the same account.
 * @param email
 */
export function hasSubAddress(email: string): boolean {
  const match = email.match(/^[^+]+(\+[^@]+)@[^@]+$/);
  return Boolean(match && !!match[1]);
}
