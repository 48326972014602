import { useEffect, useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";
import { MOBILE_BREAKPOINT } from "@olivahealth/oli-ui/utils/breakpoints";
import { DotLoader, Logo, Text } from "@olivahealth/oli-ui";
import { useSigninCheck } from "@olivahealth/firebase/client";
import logger from "@olivahealth/logger/client";
import Image from "../../../../components/common/Image";
import useTranslation from "../../../../hooks/useTranslation";
import { useAuthAuthorized } from "../../../../services/contexts/AuthAuthorizedContext";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";
import ErrorPage from "../../../pages/Error";
import { useSignOut } from "../../../../services/contexts/SignOutContext";
import AuthPanel from "../AuthPanel";
import Trans from "../../../molecules/Trans";
import * as s from "./styles";

export type AuthMode = "signup" | "login";

export default function AuthPage() {
  const { t } = useTranslation("auth", { keyPrefix: "authPage" });
  const { status, setStatus } = useAuthAuthorized();
  const { status: signInStatus, data } = useSigninCheck();
  const [authMode] = useState<AuthMode>("login");
  const { signOut } = useSignOut();
  const { trackEvent } = useAmplitude();
  const width = useWindowWidth();
  const isMobile = width < MOBILE_BREAKPOINT;

  useEffect(() => {
    trackEvent("Landing page opened");
  }, [trackEvent]);

  if (signInStatus === "error" || status === "error") {
    logger.error("[AuthPage]", "Error on user logging in", {
      signInStatus,
      status,
      data,
    });
    return (
      <ErrorPage
        title={t("error.title")}
        description=""
        buttonLabel={t("error.buttonLabel")}
        onClickButton={() => {
          setStatus("success");
          signOut("Auth page error button click");
        }}
      />
    );
  }

  const initialRender =
    status === "success" && typeof data?.signedIn === "undefined";

  if (signInStatus === "loading" || status === "loading" || initialRender) {
    return (
      <div className="flex h-full w-full">
        <div className="m-auto flex">
          <DotLoader />
        </div>
      </div>
    );
  }

  return (
    <div className={s.page} data-dd-privacy="mask-user-input">
      <div className={s.imageWrapper}>
        <Image
          priority
          placeholder="blur"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC5SURBVHgBNVCLlcMwCJPAA95EN1+HaqCS3OYlsY31QfD/7zUgQSx39dN2vdGReoFaqHyqyfJdGazSBQGjVxAYrPV0E1XlAgydAEV4tE7EjNtTp2BVBPZtQoBHiNJp3mtgFJeNFN2eFClXoOUi/7XZqEeDuqMqAymajmtfI2Ipk5hlJYO6avuo4Kj3+3VjvoSZHBKYxIkgU8J9UuVJ45zbo4OO/b5kjylbhZkn6XPpmZk4nuFkqEDuwQ9fmnnVeOWSrwAAAABJRU5ErkJggg=="
          src={
            isMobile
              ? "/img/authPage/background_mobile.jpg"
              : "/img/authPage/background.jpg"
          }
          alt={t("backgroundAlt")}
          height={2160}
          width={3840}
          className={s.image}
        />
      </div>
      <div className={s.contentWrapper}>
        <div className="mb-8 md:mb-0">
          <Logo variant="full" color="white" height={28} />
        </div>
        <div className={s.contentGrid}>
          <div className={s.leftColumn}>
            <Text
              component="h1"
              color="white"
              fontFamily="centra"
              size="5xl"
              weight="bold"
              gutter="lg"
            >
              <Trans
                i18nKey={t("title")}
                components={[
                  <span
                    key="text"
                    className="font-recife italic text-orange-300"
                  />,
                ]}
              />
            </Text>
            <Text color="white" gutter="lg">
              {t("description")}
            </Text>
          </div>
          <div className={s.rightColumn}>
            <AuthPanel authMode={authMode} />
          </div>
        </div>
      </div>
    </div>
  );
}
