import { useUser } from "@olivahealth/firebase/client";
import { Button, Text } from "@olivahealth/oli-ui";
import { useSignOut } from "../../../../services/contexts/SignOutContext";
import useTranslation from "../../../../hooks/useTranslation";

export default function SignOut(): JSX.Element {
  const { signOut } = useSignOut();
  const { data: user } = useUser();
  const { t } = useTranslation("auth", { keyPrefix: "signOut" });

  return (
    <div>
      <Text variant="h3" gutter="xl">
        {t("title")}
      </Text>
      {user?.email && (
        <Text color="secondary" gutter="lg">
          {t("emailInfo", { email: user.email })}
        </Text>
      )}
      <Button
        variant="primary"
        width="full"
        onClick={() => signOut("Auth sign out button click")}
      >
        {t("buttonLabel")}
      </Button>
    </div>
  );
}
