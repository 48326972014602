export enum CustomerEngagementServiceEvents {
  AttendedSession = "User attended a session",
  AttendsClass = "User attends a class",
  AttendsTalk = "User attends a talk",
  BookedCheckIn = "User booked a check-in",
  BookedTherapy = "User booked a therapy",
  FirstAppOpen = "First app open",
  Joined = "Joined",
  MatchingSurveyCompleted = "User completed matching survey",
  RegisteredClass = "User registers to a class",
  RegisteredTalk = "User registers to a talk",
  SelfGuidedContentStarted = "User started consuming self-guided content",
  WellbeingProfileCreated = "User created their wellbeing profile",
  WellbeingProfileStarted = "User started creating their wellbeing profile",
}
