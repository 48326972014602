import { useForm } from "react-hook-form";
import {
  Button,
  ExclamationIcon,
  Input,
  LoadingSpinnerIcon,
  Text,
} from "@olivahealth/oli-ui";
import {
  SSO_INPUT_ID,
  TRACK_EVENT_SSO_PROVIDER_NAME,
  VALID_EMAIL_REGEXP,
} from "@olivahealth/constants";

import useSSOVerifyEmail, {
  SSOOrganisation,
} from "../../../../hooks/useSSOVerifyEmail";
import useLoginWithSSO from "../../../../hooks/useLoginWithSSO";
import useTranslation from "../../../../hooks/useTranslation";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";
import { LoginErrorType } from "../../../../types/tracking";

interface FieldValues {
  [SSO_INPUT_ID]: string;
}

interface Props {
  isLogin: boolean;
  setEnabledSSOOrganisations: (
    enabledSSOOrganisations: SSOOrganisation[],
  ) => void;
  setEmail: (email: string) => void;
}

export default function EmailForm({
  isLogin,
  setEnabledSSOOrganisations,
  setEmail,
}: Props) {
  const { trackEvent } = useAmplitude();

  const verifyEmail = useSSOVerifyEmail();
  const loginWithSSO = useLoginWithSSO();

  const { t } = useTranslation("auth", { keyPrefix: "sso" });

  const { register, handleSubmit, formState, setError } = useForm<FieldValues>({
    mode: "onChange",
  });

  const onSubmit = handleSubmit(async (formFields) => {
    trackEvent("Login attempted", { provider: TRACK_EVENT_SSO_PROVIDER_NAME });

    const emailValue = formFields[SSO_INPUT_ID];

    const ssoVerifyEmail = await verifyEmail({
      email: emailValue,
    });

    if (!ssoVerifyEmail) {
      setError(SSO_INPUT_ID, {
        type: "custom",
        message: isLogin ? t("generalErrorLogin") : t("generalErrorSignup"),
      });
      trackEvent("Login error occurred", {
        provider: TRACK_EVENT_SSO_PROVIDER_NAME,
        errorType: LoginErrorType.SSO_ERROR_DURING_EMAIL_VERIFICATION,
      });
      return;
    }

    if (!ssoVerifyEmail?.shouldStartSSOFlow) {
      setError(SSO_INPUT_ID, {
        type: "custom",
        message: isLogin
          ? t("validationErrorLogin")
          : t("validationErrorSignup"),
      });
      trackEvent("Login error occurred", {
        provider: TRACK_EVENT_SSO_PROVIDER_NAME,
        errorType: LoginErrorType.SSO_NOT_ENABLED_FOR_ORG,
      });
      return;
    }

    const shouldStartMultiOrgSelectFlow =
      ssoVerifyEmail.shouldStartSSOFlow &&
      ssoVerifyEmail.enabledSSOOrganisations.length > 1;
    if (shouldStartMultiOrgSelectFlow) {
      setEnabledSSOOrganisations(ssoVerifyEmail.enabledSSOOrganisations);
      setEmail(emailValue);
      trackEvent("Login error occurred", {
        provider: TRACK_EVENT_SSO_PROVIDER_NAME,
        errorType: LoginErrorType.SSO_MULTI_ORG_SELECT_ERROR,
      });
      return;
    }

    const organisation = ssoVerifyEmail.enabledSSOOrganisations[0];

    const successfulLogin = await loginWithSSO({
      email: emailValue,
      primarySSODomain: organisation?.primarySSODomain,
    });

    if (!successfulLogin) {
      setError(SSO_INPUT_ID, {
        type: "custom",
        message: isLogin ? t("generalErrorLogin") : t("generalErrorSignup"),
      });
      return;
    }
  });

  const hasError = Boolean(formState.errors[SSO_INPUT_ID]);
  const errorMessage = hasError
    ? formState.errors[SSO_INPUT_ID]?.message || t("invalidEmail")
    : null;

  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="m-2">
        <Text weight="bold">{t("emailInputLabel")}</Text>
      </div>
      <Input
        id={SSO_INPUT_ID}
        register={register}
        validationOptions={{
          pattern: VALID_EMAIL_REGEXP,
          required: true,
        }}
        type="email"
        hasError={hasError}
        iconRight={hasError ? <ExclamationIcon variant="triangle" /> : null}
        placeholder={t("emailInputPlaceholder")}
        {...(errorMessage && { errorMessage })}
      />
      <div className="mt-8 w-full">
        <Button
          variant="primary"
          type="submit"
          width="full"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? <LoadingSpinnerIcon color="white" /> : null}
          {t(`${isLogin ? "loginButton" : "signupButton"}`)}
        </Button>
      </div>
    </form>
  );
}
