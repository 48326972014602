import { useEffect, useState } from "react";
import { useSigninCheck } from "@olivahealth/firebase/client";
import { NexusGenObjects } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";
import { DotLoader } from "@olivahealth/oli-ui";
import cx from "classnames";
import SocialSignUpForm from "../SignUp/SocialSignUpForm";
import SignOut from "../SignOut";
import PrivacyNotice from "../PrivacyNotice";
import SSOPanel from "../SSOPanel";
import { useSharedStoreContext } from "../../../../services/contexts/SharedStoreContext";
import EmailPanel from "./EmailPanel";
import { AuthMethod } from "./types";
import * as s from "./styles";
import usePresetAuthMethod from "./usePresetAuthMethod";

interface Props {
  authMode: AuthMode;
  invitation?: Pick<NexusGenObjects["Invitation"], "id" | "email">;
}

export type AuthMode = "signup" | "login";

export default function AuthPanel({
  authMode,
  invitation,
}: Props): JSX.Element {
  const [email, setEmail] = useState<string>();
  const [authMethod, setAuthMethod] = useState<AuthMethod>("social");
  const { data: signInData, status } = useSigninCheck();
  const isLogin = authMode === "login";
  usePresetAuthMethod({ handleIsValidPresetAuthMethod: setAuthMethod });
  const {
    login: { recentlyEnteredEmail },
  } = useSharedStoreContext();

  /**
   * We want to render the panel with full width if the user is on the accept invitation page
   */
  const renderFullWidth = Boolean(invitation);

  useEffect(() => {
    // On initial render, set the email to the recently entered email from shared state
    if (recentlyEnteredEmail) {
      setEmail(recentlyEnteredEmail);
    }
  }, []);

  const toggleAuthMethod = (authMethod: AuthMethod) => () => {
    setAuthMethod(authMethod);
  };

  if (status === "loading" && isLogin) {
    return <DotLoader />;
  }

  if (signInData?.signedIn && (isLogin || invitation)) {
    return (
      <div className={s.panel}>
        <SignOut />
      </div>
    );
  }

  const getPanelToRender = () => {
    if (authMethod === "email") {
      return (
        <EmailPanel
          invitation={invitation}
          toggleAuthMethod={toggleAuthMethod}
          isLogin={isLogin}
          setEmail={setEmail}
        />
      );
    }

    if (authMethod === "sso" || authMethod === "sso-enforced") {
      return (
        <SSOPanel
          isLogin={isLogin}
          navigateToAuthPanel={toggleAuthMethod("social")}
          showSSOEnforceMessageInitialValue={authMethod === "sso-enforced"}
          email={email}
          setEmail={setEmail}
        />
      );
    }

    return (
      <SocialSignUpForm
        isLogin={isLogin}
        invitation={invitation}
        toggleAuthMethod={toggleAuthMethod}
      />
    );
  };

  return (
    <div
      className={cx(s.panel, {
        "max-w-xs": !renderFullWidth,
      })}
    >
      {getPanelToRender()}
      {authMethod === "social" && (
        <div className="mt-5">
          <PrivacyNotice isLogin={isLogin} />
        </div>
      )}
    </div>
  );
}
