import tokens from "@olivahealth/oli-ui/theme/tokens";
import cx from "classnames";
import * as s from "./styles";

export default function InfinityLoader({
  size = 80,
}: {
  size?: number;
}): JSX.Element {
  return (
    <svg
      aria-label="loader"
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 100 100"
    >
      <path
        fill="none"
        stroke={tokens.colors["purple"][500]}
        strokeWidth="2"
        strokeDasharray="128.29446411132812 128.29446411132812"
        d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
        strokeLinecap="round"
      >
        <animate
          attributeName="stroke-dashoffset"
          repeatCount="indefinite"
          dur="1.4s"
          keyTimes="0;1"
          values="0;256.58892822265625"
        ></animate>
      </path>
    </svg>
  );
}

export function CenteredInfinityLoader({
  children,
  height = "screen",
}: {
  children?: React.ReactNode;
  height?: "screen" | "full";
}): JSX.Element {
  return (
    <div className={cx(s.loader, `h-${height}`)} data-testid="loader">
      <InfinityLoader />
      {children}
    </div>
  );
}
