import { AlertBanner, Button, Text } from "@olivahealth/oli-ui";
import useTranslation from "../../../../hooks/useTranslation";

interface Props {
  email?: string;
  startSSOLoginFlow: () => void;
  isLogin: boolean;
}

export default function EnforceSSOMessage({
  email,
  startSSOLoginFlow,
  isLogin,
}: Props) {
  const { t } = useTranslation("auth", { keyPrefix: "sso.enforceSSO" });

  return (
    <div className="w-full">
      <div className="m-2">
        {email && (
          <Text align="center" gutter="lg" color="secondary">
            {email}
          </Text>
        )}
      </div>
      <AlertBanner
        variant="error"
        heading={isLogin ? t("buttonMessageLogin") : t("buttonMessageSignup")}
      />
      <div className="mt-8 w-full">
        <Button
          variant="primary"
          type="submit"
          width="full"
          onClick={startSSOLoginFlow}
        >
          {isLogin ? t("buttonLogin") : t("buttonSignup")}
        </Button>
      </div>
    </div>
  );
}
