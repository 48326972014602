import { FormEventHandler, ReactNode, useCallback, useState } from "react";
import { graphql, useMutation } from "react-relay";
import { FieldErrors, useForm, UseFormRegister } from "react-hook-form";
import { useRouter } from "next/router";
import { useIntercom } from "react-use-intercom";
import {
  getAdditionalUserInfo,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "@firebase/auth";
import { useAuth } from "@olivahealth/firebase/client";
import { refreshAuthToken } from "@olivahealth/utils/relayUtils";
import { NexusGenObjects } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";
import { CURRENT_ONBOARDING_VERSION } from "@olivahealth/constants";
import logger from "@olivahealth/logger/client";
import { Logos } from "@olivahealth/oli-ui";
import { CustomerEngagementServiceEvents } from "@olivahealth/graphql-server/src/domain/value-objects/CustomerEngagementServiceEvents";
import useTranslation, {
  type TFunction,
} from "../../../../hooks/useTranslation";
import { OlivaHook } from "../../../../hooks/OlivaHook";
import { useAuthAuthorized } from "../../../../services/contexts/AuthAuthorizedContext";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";
import { UserRoleEnum } from "../../../../services/contexts/__generated__/UserDataContextQuery.graphql";
import { useSignOut } from "../../../../services/contexts/SignOutContext";
import useSSOVerifyEmail from "../../../../hooks/useSSOVerifyEmail";
import { useSharedStoreContext } from "../../../../services/contexts/SharedStoreContext";
import { LoginFormFields } from "../EmailLoginForm/useEmailLoginForm";
import useMultiFactorAuthentication from "../../../../hooks/useMultiFactorAuthentication";
import { useSocialSignUpAcceptInvitationMutation as Mutation } from "./__generated__/useSocialSignUpAcceptInvitationMutation.graphql";

interface HookProps {
  invitation?: Pick<NexusGenObjects["Invitation"], "id" | "email">;
  isLogin: boolean;
}

interface UseSocialSignUpData {
  multifactorStarted: boolean;
  multifactorLoading: boolean;
  isMfaRoute: boolean;
  formError: string | null;
  formInputProps: {
    register: UseFormRegister<LoginFormFields>;
    errors: FieldErrors<LoginFormFields>;
  };
}

interface UseSocialSignUpEffects {
  t: TFunction;
  onFormSubmit: FormEventHandler<HTMLFormElement>;
  openSocialSignUp: (provider: ProviderType) => Promise<void>;
}

type SocialSignUpReturn = OlivaHook<
  UseSocialSignUpData,
  UseSocialSignUpEffects
>;

type ProviderType = GoogleAuthProvider | OAuthProvider;

enum SocialProvider {
  MICROSOFT = "Microsoft",
  GOOGLE = "Google",
  SLACK = "Slack",
}

interface ProviderButton {
  id: SocialProvider;
  provider: ProviderType;
  logo: ReactNode;
}

const useSocialSignUpAcceptInvitationMutation = graphql`
  mutation useSocialSignUpAcceptInvitationMutation($invitationId: ID!) {
    createUser: acceptInvitation(invitationId: $invitationId) {
      __typename
      ... on UserCreated {
        uid
      }
      ... on ForbiddenError {
        reason
      }
      ... on NotFoundError {
        reason
      }
    }
  }
`;

export const ProviderButtons: ProviderButton[] = [
  {
    id: SocialProvider.GOOGLE,
    provider: new GoogleAuthProvider(),
    logo: <Logos variant="google" />,
  },
  {
    id: SocialProvider.MICROSOFT,
    provider: new OAuthProvider("microsoft.com"),
    logo: <Logos variant="microsoft" />,
  },
  {
    id: SocialProvider.SLACK,
    provider: new OAuthProvider("oidc.slack"),
    logo: <Logos variant="slack" />,
  },
];

export default function useSocialSignUp({
  invitation,
  isLogin,
}: HookProps): SocialSignUpReturn {
  const verifyEmail = useSSOVerifyEmail();
  const auth = useAuth();
  const { signOut } = useSignOut();
  const [providerId, setProviderId] = useState<string | null>(null);
  const { t } = useTranslation("auth");
  const { trackEvent: trackIntercomEvent } = useIntercom();

  const {
    multifactorLoading,
    multifactorStarted,
    startMutifactorVerification,
    finalizeMultifactorVerification,
  } = useMultiFactorAuthentication("social-recaptcha-container");
  const { setStatus, setErrorMessage } = useAuthAuthorized();
  const { trackEvent, identifyAmplitudeUser } = useAmplitude();
  const { route, push } = useRouter();
  const isMfaRoute = isLogin && route === "/profile/mfa";

  const { register, handleSubmit, formState } = useForm<LoginFormFields>({
    mode: "onChange",
  });
  const [formError, setFormError] = useState<
    typeof FORM_GENERIC_ERROR_MESSAGE | string | null
  >(null);
  const [mutateAcceptInvitation] = useMutation<Mutation>(
    useSocialSignUpAcceptInvitationMutation,
  );
  const { setSharedStore } = useSharedStoreContext();

  const FORM_GENERIC_ERROR_MESSAGE = t("auth.genericSignUpErrorMessage");

  const triggerMutation = (config) => {
    if (invitation) {
      mutateAcceptInvitation({
        variables: {
          invitationId: invitation.id,
        },
        ...config,
      });
    }
  };

  const handleError = (errorMessage: string) => {
    setFormError(errorMessage);
    setErrorMessage(errorMessage);
    setStatus("error");

    signOut("Social sign up error");
  };

  const loginUser = async (providerId: string) => {
    await auth?.currentUser?.getIdToken(true);
    refreshAuthToken();
    const userToken = await auth.currentUser?.getIdTokenResult();
    await identifyAmplitudeUser(
      (userToken?.claims?.["organisation"] as string) ?? "",
      (userToken?.claims?.["role"] as UserRoleEnum[]) ?? ["UNKNOWN"],
    );

    trackEvent("Signed up", {
      provider: providerId,
      onboardingVersion: CURRENT_ONBOARDING_VERSION,
    });
    trackIntercomEvent(CustomerEngagementServiceEvents.Joined, {
      date: new Date(),
    });
    setStatus("success");
    logger.info("useSocialSignUp", "User signed up correctly", {
      expiration: userToken?.claims?.exp,
      userId: userToken?.claims?.sub,
      authTime: userToken?.claims?.auth_time,
      role: userToken?.claims?.role,
    });

    if (invitation) {
      trackEvent("Invite accepted", {
        provider: providerId,
        onboardingVersion: CURRENT_ONBOARDING_VERSION,
      });

      push("/");
    }
  };

  const addUser = (providerId: string) => {
    triggerMutation({
      onError: () => {
        handleError(FORM_GENERIC_ERROR_MESSAGE);
      },
      onCompleted: (data) => {
        const onMutationCompleted = async (data: Mutation["response"]) => {
          if (data && data?.createUser?.__typename !== "UserCreated") {
            if (data?.createUser?.__typename == "%other") {
              handleError(FORM_GENERIC_ERROR_MESSAGE);
            }
            if (data?.createUser?.__typename == "ForbiddenError") {
              handleError(data.createUser.reason);
            }
            return;
          }

          await loginUser(providerId);
        };

        onMutationCompleted(data);
      },
    });
  };

  const onSubmit = handleSubmit(async ({ smsCode }) => {
    try {
      await finalizeMultifactorVerification(smsCode);

      const userToken = await auth.currentUser?.getIdTokenResult();
      await identifyAmplitudeUser(
        (userToken?.claims?.["organisation"] as string) ?? "",
        (userToken?.claims?.["role"] as any[]) ?? ["UNKNOWN"],
      );
      trackEvent("Logged in", { provider: providerId });
      setStatus("success");
      logger.info("useSocialSignUp", "User logged in correctly", {
        expiration: userToken?.claims?.exp,
        userId: userToken?.claims?.sub,
        authTime: userToken?.claims?.auth_time,
        role: userToken?.claims?.role,
      });
    } catch (error) {
      handleError(t("multifactor.generalError"));
    }
  });

  const socialSignUp = useCallback(
    async (provider: ProviderType) => {
      try {
        setProviderId(provider.providerId);
        setStatus("in_progress");
        const user = await signInWithPopup(auth, provider);
        const additionalUserInfo = getAdditionalUserInfo(user);

        const userEmail = user.user.email || "";
        const ssoVerifyEmail = await verifyEmail({ email: userEmail });
        if (ssoVerifyEmail?.shouldStartSSOFlow) {
          setSharedStore({
            login: {
              recentlyEnteredEmail: userEmail,
            },
          });
          signOut("Enforcing login with SSO");
          if (invitation && !isLogin) {
            await push(
              `/invitation/${invitation.id}/accept?authMethod=sso-enforced`,
            );
          } else {
            await push("/?authMethod=sso-enforced");
          }
          return;
        }

        // User has not accepted the invitation yet
        if (!invitation) {
          if (additionalUserInfo?.isNewUser) {
            await user.user.delete();
            handleError(t("socialSignup.createAccountNoInvitationError"));
          } else {
            await loginUser(provider.providerId);
          }
          return;
        }

        // The email does not belong to the invitation
        if (invitation && user.user.email != invitation.email) {
          if (additionalUserInfo?.isNewUser) {
            await user.user.delete();
          }
          handleError(
            t("socialSignup.createAccountEmailError", {
              email: invitation.email,
            }),
          );
          return;
        }

        addUser(provider.providerId);
      } catch (error) {
        if ((error as any).code === "auth/multi-factor-auth-required") {
          startMutifactorVerification(error);
          return;
        }

        if (
          (error as any).code !== "auth/popup-closed-by-user" &&
          (error as any).code !== "auth/cancelled-popup-request"
        ) {
          setStatus("error");
          logger.error(
            "useSocialSignUp",
            "Error signing up using social accounts",
            {
              error,
            },
          );
        } else {
          setStatus("success");
        }
      }
    },
    [invitation, startMutifactorVerification],
  );

  return {
    status: true ? "pending" : "initial",
    data: {
      isMfaRoute,
      multifactorStarted,
      multifactorLoading,
      formError,
      formInputProps: {
        register,
        errors: formState.errors,
      },
    },
    effects: {
      t,
      onFormSubmit: onSubmit,
      openSocialSignUp: socialSignUp,
    },
  };
}
