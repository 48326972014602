import { useState } from "react";
import {
  Button,
  LoadingSpinnerIcon,
  Text,
  Dropdown,
} from "@olivahealth/oli-ui";
import { TRACK_EVENT_SSO_PROVIDER_NAME } from "@olivahealth/constants";

import useTranslation from "../../../../hooks/useTranslation";
import useLoginWithSSO from "../../../../hooks/useLoginWithSSO";
import { SSOOrganisation } from "../../../../hooks/useSSOVerifyEmail";
import { LoginErrorType } from "../../../../types/tracking";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";

interface Props {
  enabledSSOOrganisations?: SSOOrganisation[];
  isLogin: boolean;
  email?: string;
}

export default function MultiOrgSelect({
  enabledSSOOrganisations,
  isLogin,
  email,
}: Props) {
  const { trackEvent } = useAmplitude();
  const [hasError, setHasError] = useState<boolean>();
  const [selected, setSelected] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("auth", { keyPrefix: "sso" });
  const loginWithSSO = useLoginWithSSO();

  if (!enabledSSOOrganisations || !email) {
    return null;
  }

  const dropdownOptions = enabledSSOOrganisations.map((org) => ({
    id: org.id,
    value: org.id,
    label: org.name,
  }));

  const handleClick = async () => {
    trackEvent("Login attempted", { provider: TRACK_EVENT_SSO_PROVIDER_NAME });

    if (!selected) {
      setHasError(true);
      trackEvent("Login error occurred", {
        provider: TRACK_EVENT_SSO_PROVIDER_NAME,
        errorType: LoginErrorType.GENERAL_APP_ERROR,
      });
      return;
    }

    setIsSubmitting(true);
    const ssoOrganisation = enabledSSOOrganisations.find(
      (org) => org.id === selected,
    );

    const successfulLogin = await loginWithSSO({
      email,
      primarySSODomain: ssoOrganisation?.primarySSODomain,
      organisationId: selected,
    });

    setIsSubmitting(false);

    if (!successfulLogin) {
      setHasError(true);
      setSelected(undefined);
      return;
    }
  };

  return (
    <div className="w-full">
      <div className="m-2">
        <Text align="center" gutter="lg" color="secondary">
          {t("chooseOrganisation")}
        </Text>
      </div>
      <div className="relative h-16 w-full">
        <Dropdown
          setSelected={(selected: string) => {
            if (hasError) {
              setHasError(false);
            }
            setSelected(selected);
          }}
          selected={selected}
          placeholder={t("chooseOrganisationPlaceholder")}
          options={dropdownOptions}
        />
      </div>
      {hasError && (
        <Text gutter="lg" color="secondary" size="sm">
          {isLogin ? t("generalErrorLogin") : t("generalErrorSignup")}
        </Text>
      )}
      <div className="mt-8 w-full">
        <Button
          variant="primary"
          type="submit"
          width="full"
          disabled={isSubmitting || !selected}
          onClick={handleClick}
        >
          {isSubmitting ? <LoadingSpinnerIcon color="white" /> : null}
          {t(`${isLogin ? "loginButton" : "signupButton"}`)}
        </Button>
      </div>
    </div>
  );
}
