import ctl from "@netlify/classnames-template-literals";

export const innerWrapper = ctl(`
  relative 
  flex 
  h-full 
  w-full 
  items-center 
  bg-[url('/img/img_background_404_page.png')] 
  bg-cover
`);

export const textRowWrapper = ctl(`
  mx-8 
  flex
  w-full 
  flex-col-reverse
  items-center 
  justify-between 
  lg:mx-40
  lg:flex-row
`);

export const leftColumnWrapper = ctl(`
  flex 
  max-w-[550px] 
  flex-col 
  items-center 
  gap-y-5 
  text-center 
  lg:items-start 
  lg:text-start
`);

export const imageWrapper = ctl(`
  relative 
  mb-5 
  flex 
  h-60 
  w-60 
  animate-move-up-down 
  lg:mb-0
  lg:w-96
  lg:h-96
`);
