import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { INTERCOM_DEFAULT_POSITION } from "@olivahealth/constants";

export default function useRepositionedIntercom({
  verticalPadding,
}: {
  verticalPadding: number;
}): void {
  const { update } = useIntercom();

  useEffect(() => {
    update({ verticalPadding });
  }, [update, verticalPadding]);

  useEffect(() => {
    return () => {
      update({ verticalPadding: INTERCOM_DEFAULT_POSITION.verticalPadding });
    };
  }, [update]);
}
