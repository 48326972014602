import {
  Button,
  CloudLockIcon,
  LetterIcon,
  LockIcon,
  Text,
} from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { NexusGenObjects } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";
import MultiFactorForm from "../MultiFactorForm";
import { CenteredInfinityLoader } from "../../../molecules/Loaders/InfinityLoader";
import { AuthMethod } from "../AuthPanel/types";
import useTranslation from "../../../../hooks/useTranslation";
import useSocialSignUp, { ProviderButtons } from "./useSocialSignUp";

interface Props {
  invitation?: Pick<NexusGenObjects["Invitation"], "id" | "email">;
  isLogin?: boolean;
  toggleAuthMethod: (authMethod: AuthMethod) => () => void;
}

export default function SocialSignUpForm({
  invitation,
  isLogin = false,
  toggleAuthMethod,
}: Props): JSX.Element {
  const {
    data: {
      isMfaRoute,
      multifactorStarted,
      multifactorLoading,
      formInputProps,
      formError,
    },
    effects: { onFormSubmit, openSocialSignUp },
  } = useSocialSignUp({
    invitation,
    isLogin,
  });
  const { t, Trans } = useTranslation("auth");
  const hasInvitation = Boolean(invitation);

  return (
    <>
      {multifactorStarted ? (
        <MultiFactorForm
          onSubmit={onFormSubmit}
          register={formInputProps.register}
        />
      ) : (
        <>
          {isMfaRoute ? (
            <div className="flex flex-col items-center gap-2 mb-6">
              <LockIcon color={tokens.colors.purple[500]} size={48} />
              <Text weight="bold" align="center" size="xl">
                {t("multiFactor.activateTitle")}
              </Text>

              <Text color="secondary" align="center">
                {t("multiFactor.activateSignInMessage")}
              </Text>
            </div>
          ) : (
            <Text
              variant="h3"
              align="center"
              gutter={hasInvitation ? "md" : "xl"}
            >
              {multifactorLoading
                ? t("multiFactor.title")
                : isLogin
                  ? t("socialSignUpForm.titleLogin")
                  : t("socialSignUpForm.titleSignup")}
            </Text>
          )}
          {!multifactorLoading ? (
            <>
              {hasInvitation && (
                <Text color="secondary" gutter="xl">
                  <Trans
                    i18nKey={t("socialSignUpForm.invitationMessage")}
                    values={{ email: invitation?.email }}
                    components={[<span key="bold" className="font-bold" />]}
                  />
                </Text>
              )}
              <div className="flex w-full flex-col space-y-2">
                {ProviderButtons.map(({ provider, id, logo }) => {
                  return (
                    <Button
                      key={id}
                      iconLeft={logo}
                      justify="start"
                      variant="quaternary"
                      onClick={() => openSocialSignUp(provider)}
                    >
                      {t(
                        `socialSignUpForm.providers.${id.toLowerCase()}.${
                          isLogin ? "login" : "signup"
                        }`,
                      )}
                    </Button>
                  );
                })}

                <Button
                  iconLeft={<LetterIcon color={tokens.colors["purple"][500]} />}
                  justify="start"
                  variant="quaternary"
                  onClick={toggleAuthMethod("email")}
                >
                  {t(
                    `socialSignUpForm.providers.email.${
                      isLogin ? "login" : "signup"
                    }`,
                  )}
                </Button>

                <Button
                  iconLeft={
                    <CloudLockIcon color={tokens.colors["purple"][500]} />
                  }
                  justify="start"
                  variant="quaternary"
                  onClick={toggleAuthMethod("sso")}
                >
                  {t(
                    `socialSignUpForm.providers.sso.${
                      isLogin ? "login" : "signup"
                    }`,
                  )}
                </Button>
              </div>
            </>
          ) : (
            <CenteredInfinityLoader height="full" />
          )}
        </>
      )}

      {formError && <p className="font-gray-600 font-sans">{formError}</p>}
      <div id="social-recaptcha-container"></div>
    </>
  );
}
