import ctl from "@netlify/classnames-template-literals";

export const activateTitle = ctl(`
  mt-4
  pb-4
  border-b
  border-blue-100
  font-sans
  text-lg
`);

export const form = ctl(`
  animate
  animate-fade-in
  duration-1000
  w-full
`);

export const formHeader = ctl(`
  flex
  mb-6
  w-full
  items-center
  justify-center
  relative
`);

export const passwordValidationGrid = ctl(`
  grid
  grid-cols-1
  gap-2
  px-2
  xs:grid-cols-2
`);

export const passwordValidationItem = ctl(`
  flex
  items-center
  gap-2
`);
