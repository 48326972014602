import { FormEventHandler, useState } from "react";
import { FormState, useForm } from "react-hook-form";
import { signInWithEmailAndPassword } from "@firebase/auth";
import logger from "@olivahealth/logger/client";
import { useAuth } from "@olivahealth/firebase/client";
import { OlivaHook } from "../../../../hooks/OlivaHook";
import useSSOVerifyEmail from "../../../../hooks/useSSOVerifyEmail";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";
import useMultiFactorAuthentication from "../../../../hooks/useMultiFactorAuthentication";
import { useAuthAuthorized } from "../../../../services/contexts/AuthAuthorizedContext";
import { AuthMethod } from "../AuthPanel/types";
import useTranslation, { TFunction } from "../../../../hooks/useTranslation";

export interface LoginFormFields {
  smsCode: string;
  email: string;
  password: string;
}

export interface Props {
  toggleAuthMethod: (authMethod: AuthMethod) => () => void;
  setEmail: (email: string) => void;
}

interface UseEmailLoginFormData {
  formErrors: FormState<LoginFormFields>["errors"];
  formSubmissionError: string;
  isButtonDisabled: boolean;
  isPasswordVisible: boolean;
  multifactorLoading: boolean;
  multifactorStarted: boolean;
  showResetPasswordForm: boolean;
}

interface UseEmailLoginFormEffects {
  handleClickForgotPasswordButton: () => void;
  onFormSubmit: FormEventHandler<HTMLFormElement>;
  register: any;
  t: TFunction;
  togglePasswordVisibility: () => void;
}

type UseEmailLoginForm = OlivaHook<
  UseEmailLoginFormData,
  UseEmailLoginFormEffects
>;

export default function useEmailLoginForm({
  toggleAuthMethod,
  setEmail,
}: Props): UseEmailLoginForm {
  const verifyEmail = useSSOVerifyEmail();
  const { trackEvent, identifyAmplitudeUser } = useAmplitude();
  const {
    multifactorLoading,
    multifactorStarted,
    startMutifactorVerification,
    finalizeMultifactorVerification,
  } = useMultiFactorAuthentication("recaptcha-container");
  const auth = useAuth();
  const { setStatus } = useAuthAuthorized();
  const { t } = useTranslation("auth", { keyPrefix: "emailLoginForm" });
  const { handleSubmit, formState, register } = useForm<LoginFormFields>({
    mode: "onBlur",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [formSubmissionError, setFormSubmissionError] = useState<string>("");

  const isButtonDisabled = formState.isSubmitting;

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((isVisible) => !isVisible);
  };

  const handleClickForgotPasswordButton = () => {
    setShowResetPasswordForm(true);
  };

  const onFormSubmit = handleSubmit(async ({ smsCode, email, password }) => {
    try {
      const ssoVerifyEmail = await verifyEmail({ email });
      if (ssoVerifyEmail?.shouldStartSSOFlow) {
        toggleAuthMethod("sso-enforced")();
        setEmail(email);
        return;
      }

      if (smsCode) {
        await finalizeMultifactorVerification(smsCode);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }

      const userToken = await auth.currentUser?.getIdTokenResult();
      await identifyAmplitudeUser(
        (userToken?.claims?.["organisation"] as string) ?? "",
        (userToken?.claims?.["role"] as any[]) ?? ["UNKNOWN"],
      );
      setStatus("success");
      trackEvent("Logged in", { provider: "password" });
      logger.info("LogInWithEmailForm", "User logged in correctly", {
        expiration: userToken?.claims?.exp,
        userId: userToken?.claims?.sub,
        authTime: userToken?.claims?.auth_time,
        role: userToken?.claims?.role,
      });
    } catch (error) {
      const errorMessage = (() => {
        if (error instanceof Error) return error.message;
        return String(error);
      })();

      if (errorMessage.includes("auth/multi-factor-auth-required")) {
        await startMutifactorVerification(error);
        return;
      }

      if (errorMessage.includes("auth/invalid-verification-code")) {
        setFormSubmissionError(t("incorrectCode"));
        return;
      }

      if (
        errorMessage.includes("auth/user-not-found") ||
        errorMessage.includes("auth/wrong-password")
      ) {
        setFormSubmissionError(t("incorrectCredentials"));
      } else {
        setFormSubmissionError(errorMessage);
      }
    }
  });

  return {
    status: "initial",
    data: {
      formSubmissionError,
      formErrors: formState.errors,
      isButtonDisabled,
      isPasswordVisible,
      multifactorLoading,
      multifactorStarted,
      showResetPasswordForm,
    },
    effects: {
      handleClickForgotPasswordButton,
      onFormSubmit,
      register,
      t,
      togglePasswordVisibility,
    },
  };
}
