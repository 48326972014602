import { getDomainFromEmail } from "@olivahealth/utils/helpers/email";
import {
  JACKSON_PRODUCT,
  SSO_MULTI_ORG_LOCAL_STORAGE_KEY,
  TRACK_EVENT_SSO_PROVIDER_NAME,
} from "@olivahealth/constants";
import logger from "@olivahealth/logger/client";

import { useAmplitude } from "../services/contexts/AmplitudeContext";
import { LoginErrorType } from "../types/tracking";
import useOAuthClient from "./useOAuthClient";

interface ILoginWithSSO {
  email: string;
  primarySSODomain?: string | null;
  organisationId?: string;
}

export default function useLoginWithSSO() {
  const oAuthClient = useOAuthClient();
  const { trackEvent } = useAmplitude();

  const saveOrganisationIdToLocalStorage = (organisationId: string) => {
    localStorage.setItem(SSO_MULTI_ORG_LOCAL_STORAGE_KEY, organisationId);
  };

  return async ({ email, primarySSODomain, organisationId }: ILoginWithSSO) => {
    const tenant = primarySSODomain || getDomainFromEmail(email);

    if (!tenant) {
      return false;
    }

    try {
      if (organisationId) {
        saveOrganisationIdToLocalStorage(organisationId);
      }
      await oAuthClient?.fetchAuthorizationCode({
        tenant,
        product: JACKSON_PRODUCT,
      });
      return true;
    } catch (e) {
      logger.error(
        "useLoginWithSSO",
        "Error trying to login or sign up with SSO",
        {
          errorMessage: (e as Error).message,
        },
      );
      trackEvent("Login error occurred", {
        provider: TRACK_EVENT_SSO_PROVIDER_NAME,
        errorType: LoginErrorType.SSO_ERROR_DURING_AUTHORISATION,
      });
      return false;
    }
  };
}
