import ctl from "@netlify/classnames-template-literals";

export const panel = ctl(`
  flex
  w-full
  flex-col
  items-center
  rounded-2xl
  bg-white
  px-5
  py-6
`);
