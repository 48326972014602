/**
 * @generated SignedSource<<04a202a7c098df321021470be4438047>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useSignUpWithEmailFormAcceptInvitationMutation$variables = {
  invitationId: string;
};
export type useSignUpWithEmailFormAcceptInvitationMutation$data = {
  readonly createUser: {
    readonly __typename: "ForbiddenError";
    readonly reason: string;
  } | {
    readonly __typename: "NotFoundError";
    readonly reason: string;
  } | {
    readonly __typename: "UserCreated";
    readonly uid: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useSignUpWithEmailFormAcceptInvitationMutation = {
  response: useSignUpWithEmailFormAcceptInvitationMutation$data;
  variables: useSignUpWithEmailFormAcceptInvitationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invitationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reason",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": "createUser",
    "args": [
      {
        "kind": "Variable",
        "name": "invitationId",
        "variableName": "invitationId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "acceptInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uid",
            "storageKey": null
          }
        ],
        "type": "UserCreated",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ForbiddenError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "NotFoundError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSignUpWithEmailFormAcceptInvitationMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSignUpWithEmailFormAcceptInvitationMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6485bd3ad92e36b743c33e150b08c74c",
    "id": null,
    "metadata": {},
    "name": "useSignUpWithEmailFormAcceptInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation useSignUpWithEmailFormAcceptInvitationMutation(\n  $invitationId: ID!\n) {\n  createUser: acceptInvitation(invitationId: $invitationId) {\n    __typename\n    ... on UserCreated {\n      uid\n    }\n    ... on ForbiddenError {\n      reason\n    }\n    ... on NotFoundError {\n      reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f41be56c7f172736678817df79ea2bf7";

export default node;
