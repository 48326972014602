import { Button, Text } from "@olivahealth/oli-ui";
import { ReactElement } from "react";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import * as s from "./styles";

interface Props {
  buttonLabel?: string;
  description?: string;
  onClickButton?: () => void;
  title?: string;
}

export default function ErrorPage({
  buttonLabel,
  description,
  onClickButton,
  title,
}: Props): ReactElement {
  const { t } = useTranslation("errorPage");
  const showButton = Boolean(buttonLabel && onClickButton);

  return (
    <div className="h-full w-full">
      <div className={s.innerWrapper}>
        <div className={s.textRowWrapper}>
          <div className={s.leftColumnWrapper}>
            <Text component="h1" variant="h1" color="white">
              {title ?? t("defaults.title")}
            </Text>
            <Text component="p" variant="h4" color="white">
              {description ?? t("defaults.description")}
            </Text>
            {showButton && (
              <Button variant="quaternary" onClick={onClickButton}>
                {buttonLabel}
              </Button>
            )}
          </div>
          <div className={s.imageWrapper}>
            <Image
              src="/img/img_meditating_man.png"
              alt="meditating man"
              fill
              sizes="50vw"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
