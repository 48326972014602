import { NexusGenEnums } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";
import { useSigninCheck } from "@olivahealth/firebase/client";
import logger from "@olivahealth/logger/client";
import { useRouter } from "next/router";
import { useIntercom } from "react-use-intercom";
import { ReactNode, useEffect } from "react";
import { CenteredInfinityLoader } from "../../../molecules/Loaders/InfinityLoader";
import AuthPage from "../AuthPage";
import ErrorPage from "../../../pages/Error";
import { useAuthAuthorized } from "../../../../services/contexts/AuthAuthorizedContext";
import { useUserData } from "../../../../services/contexts/UserDataContext";
import { useIntercomBootProps } from "../../../../services/contexts/IntercomProvider";
import { useSignOut } from "../../../../services/contexts/SignOutContext";
import useTranslation from "../../../../hooks/useTranslation";

interface Props {
  children: ReactNode;
  roles: NexusGenEnums["UserRoleEnum"][] | "ANONYMOUS";
}

export default function AuthMiddleware({
  children,
  roles,
}: Props): JSX.Element {
  const { signOut } = useSignOut();
  const { update, boot } = useIntercom();
  const intercomProps = useIntercomBootProps();
  const { route } = useRouter();
  const { status: authAuthorizedStatus } = useAuthAuthorized();
  const { status: signInCheckStatus, data: signInCheckResult } =
    useSigninCheck();
  const { status: userDataStatus, data: user } = useUserData();
  const { t } = useTranslation("auth", { keyPrefix: "authMiddleware" });

  useEffect(() => {
    boot(intercomProps);
    update(intercomProps);
  }, [boot, update, intercomProps]);

  const hasError =
    authAuthorizedStatus === "error" ||
    signInCheckStatus === "error" ||
    userDataStatus === "error";

  const hasRequiredRoles =
    roles.length > 0 ? user?.role?.some((item) => roles.includes(item)) : true;
  const isLoading =
    [authAuthorizedStatus, signInCheckStatus, userDataStatus].some(
      (status) => status === "loading",
    ) ||
    typeof window === "undefined" ||
    (signInCheckResult?.signedIn && !user);

  if (isLoading && !hasError && !user) {
    return <CenteredInfinityLoader />;
  }

  // For pages such as SignUp or EmployeeAcceptInvitation we don't have a token with roles to check, so we bypass it.
  if (roles === "ANONYMOUS") {
    return children as JSX.Element;
  }

  if (!signInCheckResult?.signedIn) {
    return <AuthPage />;
  }

  if (user?.status === "SUSPENDED") {
    logger.warn("AuthMiddleware", "Suspended user tried to access", {
      user,
    });
    return (
      <ErrorPage
        title={t("userSuspendedError.title")}
        description={t("userSuspendedError.description")}
        buttonLabel={t("userSuspendedError.buttonLabel")}
        onClickButton={() =>
          signOut(
            "Auth middleware error page due to suspended user sign out button click",
          )
        }
      />
    );
  }

  if (hasError) {
    signOut("Auth middleware has error");
    return children as JSX.Element;
  }

  if (!hasRequiredRoles) {
    logger.warn("AuthMiddleware", "Unauthorized user tried to view the page", {
      user,
      route,
    });
    return (
      <ErrorPage
        title={t("unauthorizedError.title")}
        description={t("unauthorizedError.description")}
        buttonLabel={t("unauthorizedError.buttonLabel")}
        onClickButton={() =>
          signOut(
            "Auth middleware error page due to not allowed to view content sign out button click",
          )
        }
      />
    );
  }

  return children as JSX.Element;
}
