import ctl from "@netlify/classnames-template-literals";

export const page = ctl(`
  h-full
  w-full
`);

export const imageWrapper = ctl(`
  relative
  h-full
  w-full
  overflow-hidden
`);

export const image = ctl(`
  fixed
  top-0
  left-0
  h-full
  w-full
  object-cover
  object-center
`);

export const contentWrapper = ctl(`
  absolute
  top-0
  left-0
  h-full
  w-full
  px-6
  py-8
  md:px-8
`);

export const contentGrid = ctl(`
  grid
  grid-cols-1
  gap-4
  md:h-[60vh]
  md:grid-cols-2
`);

export const leftColumn = ctl(`
  w-full
  md:mx-auto
  md:flex
  md:max-w-md
  md:flex-col
  md:justify-center
`);

export const rightColumn = ctl(`
  flex
  items-center
  justify-center
`);
