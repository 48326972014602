import { getDomainFromEmail } from "@olivahealth/utils/helpers/email";
import { getGraphqlUrl } from "@olivahealth/utils/relayUtils";
import logger from "@olivahealth/logger/client";
import Organisation from "@olivahealth/graphql-server/src/domain/value-objects/Organisation";

interface ISSOVerifyEmail {
  email: string;
}

export type SSOOrganisation = Pick<Organisation, "id" | "name"> & {
  primarySSODomain: string | null;
};

interface SSOVerifyResponse {
  shouldStartSSOFlow: boolean;
  enabledSSOOrganisations: SSOOrganisation[];
}

interface SSOVerifyEmail {
  shouldStartSSOFlow: boolean;
  enabledSSOOrganisations: SSOOrganisation[];
}

export default function useSSOVerifyEmail() {
  return async ({ email }: ISSOVerifyEmail): Promise<SSOVerifyEmail | null> => {
    const domain = getDomainFromEmail(email);

    if (!domain) {
      return null;
    }

    try {
      const ssoCheck: SSOVerifyResponse = await fetch(
        `${getGraphqlUrl()}/sso/verify?email=${encodeURIComponent(email)}`,
      ).then((response) => response.json());

      return {
        shouldStartSSOFlow: ssoCheck.shouldStartSSOFlow,
        enabledSSOOrganisations: ssoCheck.enabledSSOOrganisations,
      };
    } catch (e) {
      logger.error(
        "useSSOVerifyEmail",
        "Error trying to verify email for SSO",
        {
          errorMessage: (e as Error).message,
          errorStack: (e as Error).stack,
        },
      );
      return null;
    }
  };
}
