import { FormEventHandler } from "react";
import { UseFormRegister } from "react-hook-form";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { Button, Input, LockIcon, Text } from "@olivahealth/oli-ui";
import useRepositionedIntercom from "../../../../hooks/useRepositionedIntercom";
import useTranslation from "../../../../hooks/useTranslation";
import type { LoginFormFields } from "../EmailLoginForm/useEmailLoginForm";
import * as s from "./styles";

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<LoginFormFields>;
}

export default function MultiFactorForm({ onSubmit, register }: Props) {
  useRepositionedIntercom({ verticalPadding: 90 });
  const { t } = useTranslation("auth", { keyPrefix: "multiFactor" });

  return (
    <form
      className="animate w-full animate-fade-in duration-1000"
      onSubmit={onSubmit}
    >
      <span className={s.headerContainerClasses}>
        <LockIcon color={tokens.colors.purple[500]} />
        <Text color="accent-primary">{t("title")}</Text>
      </span>

      <p className="p-5 pt-0 font-sans text-gray-800">{t("instructions")}</p>
      <div className="flex flex-col">
        <Input
          id="smsCode"
          register={register}
          label={t("smsCodeInput.label")}
          placeholder={t("smsCodeInput.placeholder")}
          type="number"
        />
      </div>
      <div className="mt-1 flex items-end justify-end">
        <Button variant="primary" type="submit">
          {t("submit")}
        </Button>
      </div>
    </form>
  );
}
