import { NexusGenObjects } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";
import {
  ArrowIcon,
  Button,
  CheckCircleIcon,
  DashCircleIcon,
  EyeIcon,
  Input,
  Text,
} from "@olivahealth/oli-ui";

import tokens from "@olivahealth/oli-ui/theme/tokens";
import useTranslation from "../../../../hooks/useTranslation";
import { AuthMethod } from "../AuthPanel/types";
import useSignUpWithEmailForm from "./useSignUpWithEmailForm";
import * as s from "./styles";

interface Props {
  invitation?: Pick<NexusGenObjects["Invitation"], "id" | "email">;
  toggleAuthMethod: (authMethod: AuthMethod) => () => void;
  setEmail: (email: string) => void;
}

export default function EmailSignUpForm({
  invitation,
  toggleAuthMethod,
  setEmail,
}: Props): JSX.Element {
  const {
    data: {
      isButtonDisabled,
      formError,
      formInputProps,
      isPasswordVisible,
      passwordValidation,
    },
    effects: { handleChangePassword, onFormSubmit, togglePasswordVisibility },
  } = useSignUpWithEmailForm({ invitation, setEmail, toggleAuthMethod });
  const { t, Trans } = useTranslation("auth", {
    keyPrefix: "emailSignUpForm",
  });

  return (
    <form className={s.form} onSubmit={onFormSubmit}>
      <span className={s.formHeader}>
        <span className="absolute left-0">
          <Button
            variant="icon"
            aria-label={t("goBackLinkAriaLabel")}
            type="button"
            size="xs"
            onClick={toggleAuthMethod("social")}
          >
            <ArrowIcon direction="left" size={24} />
          </Button>
        </span>
        <Text variant="h3" align="center">
          {t("title")}
        </Text>
      </span>
      {invitation?.email && (
        <Text color="secondary" align="center" gutter="md">
          <Trans
            i18nKey={t("subtitle")}
            values={{ email: invitation.email }}
            components={[<span key="bold" className="font-bold" />]}
          />
        </Text>
      )}
      <div className="mb-10 flex flex-col gap-2">
        <Input
          required
          id="password"
          register={formInputProps.register}
          label={t("fields.password.label")}
          placeholder={t("fields.password.placeholder")}
          type={isPasswordVisible ? "text" : "password"}
          validationOptions={{ required: true }}
          iconRight={
            <div
              role="button"
              tabIndex={0}
              className="cursor-pointer"
              onClick={togglePasswordVisibility}
              onKeyDown={togglePasswordVisibility}
            >
              <EyeIcon
                color={tokens.colors.neutral[900]}
                variant={isPasswordVisible ? "open" : "closed"}
              />
            </div>
          }
          hasError={Boolean(formInputProps.errors.password)}
          errorMessage={t("fields.password.errorMessage")}
          onChange={handleChangePassword}
        />
        <div className={s.passwordValidationGrid}>
          <div className={s.passwordValidationItem}>
            {passwordValidation.length ? (
              <CheckCircleIcon color={tokens.colors.green[500]} />
            ) : (
              <DashCircleIcon color={tokens.colors.neutral[600]} />
            )}
            <Text color="secondary">
              {t("fields.password.validation.length")}
            </Text>
          </div>
          <div className={s.passwordValidationItem}>
            {passwordValidation.uppercase ? (
              <CheckCircleIcon color={tokens.colors.green[500]} />
            ) : (
              <DashCircleIcon color={tokens.colors.neutral[600]} />
            )}
            <Text color="secondary">
              {t("fields.password.validation.uppercase")}
            </Text>
          </div>
          <div className={s.passwordValidationItem}>
            {passwordValidation.symbol ? (
              <CheckCircleIcon color={tokens.colors.green[500]} />
            ) : (
              <DashCircleIcon color={tokens.colors.neutral[600]} />
            )}
            <Text color="secondary">
              {t("fields.password.validation.symbol")}
            </Text>
          </div>
          <div className={s.passwordValidationItem}>
            {passwordValidation.number ? (
              <CheckCircleIcon color={tokens.colors.green[500]} />
            ) : (
              <DashCircleIcon color={tokens.colors.neutral[600]} />
            )}
            <Text color="secondary">
              {t("fields.password.validation.number")}
            </Text>
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-center">
        <Button variant="primary" type="submit" disabled={isButtonDisabled}>
          {t("buttonLabel")}
        </Button>
      </div>
      {formError !== "" && (
        <div className="mt-3">
          <Text color="error">{formError}</Text>
        </div>
      )}
    </form>
  );
}
