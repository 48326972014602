import { graphql, useMutation } from "react-relay";
import { OlivaHook } from "../../../../hooks/OlivaHook";

import { useFixInvitationStaleUserMutation as Mutation } from "./__generated__/useFixInvitationStaleUserMutation.graphql";

interface Props {
  invitationId?: string;
}

interface UseFixInvitationStaleUserEffects {
  fixInvitationStaleUser: () => Promise<boolean>;
}

type UseFixInvitationStaleUser = OlivaHook<
  Record<string, unknown>,
  UseFixInvitationStaleUserEffects
>;

const useFixInvitationStaleUserMutation = graphql`
  mutation useFixInvitationStaleUserMutation($invitationId: ID!) {
    fixInvitationStaleUser(invitationId: $invitationId) {
      __typename
      ... on FixedInvitationStaleUser {
        success
      }
      ... on ForbiddenError {
        reason
      }
    }
  }
`;

export default function useFixInvitationStaleUser({
  invitationId,
}: Props): UseFixInvitationStaleUser {
  const [mutate, loadingMutation] = useMutation<Mutation>(
    useFixInvitationStaleUserMutation,
  );

  const fixInvitationStaleUser = () => {
    if (!invitationId) {
      return Promise.resolve(false);
    }
    return new Promise<boolean>((resolve, reject) => {
      mutate({
        variables: {
          invitationId,
        },
        onCompleted(response) {
          resolve(
            response.fixInvitationStaleUser.__typename ===
              "FixedInvitationStaleUser"
              ? response.fixInvitationStaleUser.success
              : false,
          );
        },
        onError(error) {
          reject(error);
        },
      });
    });
  };

  return {
    status: loadingMutation ? "pending" : "initial",
    data: {},
    effects: {
      fixInvitationStaleUser,
    },
  };
}
