import { useEffect } from "react";
import { useRouter } from "next/router";

import { AuthMethod, isAuthMethod } from "./types";

interface Props {
  handleIsValidPresetAuthMethod: (authMethod: AuthMethod) => void;
}

export default function usePresetAuthMethod({
  handleIsValidPresetAuthMethod,
}: Props) {
  const router = useRouter();

  useEffect(() => {
    const { query } = router;
    const presetAuthMethod: string | undefined = Array.isArray(query.authMethod)
      ? query.authMethod?.[0]
      : query.authMethod;

    if (presetAuthMethod && isAuthMethod(presetAuthMethod)) {
      handleIsValidPresetAuthMethod(presetAuthMethod);
      delete query.authMethod;
      router.replace({ pathname: router.pathname, query });
    }
  }, [handleIsValidPresetAuthMethod, router]);
}
