import { Text } from "@olivahealth/oli-ui";
import useTranslation from "../../../../hooks/useTranslation";

interface Props {
  isLogin: boolean;
}

export default function PrivacyNotice({ isLogin }: Props): JSX.Element {
  const { t, Trans } = useTranslation("auth", { keyPrefix: "privacyNotice" });

  return (
    <Text color="secondary" size="sm">
      <Trans
        i18nKey={t("message")}
        values={{ method: isLogin ? t("login") : t("signup") }}
        components={[
          <a
            key="privacyLink"
            className="cursor-pointer underline"
            href="https://legal.oliva.health/privacy-notice"
            target="_blank"
            rel="noopener noreferrer"
          />,
          <a
            key="termsLink"
            className="cursor-pointer underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://legal.oliva.health/platform-terms-of-use"
          />,
        ]}
      />
    </Text>
  );
}
