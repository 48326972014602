/**
 * @generated SignedSource<<df010d221a31f6a9f4a9b9a927d71294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useFixInvitationStaleUserMutation$variables = {
  invitationId: string;
};
export type useFixInvitationStaleUserMutation$data = {
  readonly fixInvitationStaleUser: {
    readonly __typename: "FixedInvitationStaleUser";
    readonly success: boolean;
  } | {
    readonly __typename: "ForbiddenError";
    readonly reason: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useFixInvitationStaleUserMutation = {
  response: useFixInvitationStaleUserMutation$data;
  variables: useFixInvitationStaleUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invitationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "invitationId",
        "variableName": "invitationId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "fixInvitationStaleUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "type": "FixedInvitationStaleUser",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          }
        ],
        "type": "ForbiddenError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFixInvitationStaleUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFixInvitationStaleUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9329170ba4806a613094ed8409528d64",
    "id": null,
    "metadata": {},
    "name": "useFixInvitationStaleUserMutation",
    "operationKind": "mutation",
    "text": "mutation useFixInvitationStaleUserMutation(\n  $invitationId: ID!\n) {\n  fixInvitationStaleUser(invitationId: $invitationId) {\n    __typename\n    ... on FixedInvitationStaleUser {\n      success\n    }\n    ... on ForbiddenError {\n      reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0c68815d19d2a19f265e0cdc954f8b2";

export default node;
