/**
 * @generated SignedSource<<53d0fd335f83d6bcbaea818c713595df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ForgotPasswordFormMutation$variables = {
  email: string;
};
export type ForgotPasswordFormMutation$data = {
  readonly resetPassword: {
    readonly success: boolean;
  };
};
export type ForgotPasswordFormMutation = {
  response: ForgotPasswordFormMutation$data;
  variables: ForgotPasswordFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "ResetPasswordMutationResponse",
    "kind": "LinkedField",
    "name": "resetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotPasswordFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotPasswordFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "761faf4dbef697bac65ab354f041bfe4",
    "id": null,
    "metadata": {},
    "name": "ForgotPasswordFormMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotPasswordFormMutation(\n  $email: ID!\n) {\n  resetPassword(email: $email) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "55a1e4cd27873e64322d791c031c93c8";

export default node;
