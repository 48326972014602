import {
  ArrowIcon,
  Button,
  DotLoader,
  EyeIcon,
  Input,
  Text,
} from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { VALID_EMAIL_REGEXP } from "@olivahealth/constants";
import ForgotPasswordForm from "../ForgotPasswordForm";
import MultiFactorForm from "../MultiFactorForm";
import useEmailLoginForm, { Props } from "./useEmailLoginForm";

export default function EmailLoginForm({
  toggleAuthMethod,
  setEmail,
}: Props): JSX.Element {
  const {
    data: {
      formErrors,
      formSubmissionError,
      isButtonDisabled,
      isPasswordVisible,
      multifactorLoading,
      multifactorStarted,
      showResetPasswordForm,
    },
    effects: {
      handleClickForgotPasswordButton,
      onFormSubmit,
      register,
      t,
      togglePasswordVisibility,
    },
  } = useEmailLoginForm({ toggleAuthMethod, setEmail });

  if (showResetPasswordForm) {
    return <ForgotPasswordForm toggleAuthMethod={toggleAuthMethod("social")} />;
  }

  return (
    <>
      {multifactorStarted ? (
        <MultiFactorForm onSubmit={onFormSubmit} register={register} />
      ) : !multifactorLoading ? (
        <form
          className="animate animate-fade-in duration-1000 w-full"
          id="loginForm"
          onSubmit={onFormSubmit}
        >
          <span className="flex mb-6 w-full items-center justify-center relative">
            <span className="absolute left-0">
              <Button
                variant="icon"
                aria-label={t("goBackLinkAriaLabel")}
                type="button"
                size="xs"
                onClick={toggleAuthMethod("social")}
              >
                <ArrowIcon direction="left" size={24} />
              </Button>
            </span>
            <Text variant="h3" align="center">
              {t("title")}
            </Text>
          </span>

          <div className="flex flex-col gap-4 mb-8">
            <Input
              id="email"
              register={register}
              label={t("email.label")}
              placeholder={t("email.placeholder")}
              type="email"
              validationOptions={{
                pattern: VALID_EMAIL_REGEXP,
              }}
              hasError={Boolean(formErrors.email)}
              errorMessage={t("email.error")}
            />
            <Input
              id="password"
              register={register}
              label={t("password.label")}
              placeholder={t("password.placeholder")}
              type={isPasswordVisible ? "text" : "password"}
              iconRight={
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-pointer"
                  onClick={togglePasswordVisibility}
                  onKeyDown={togglePasswordVisibility}
                >
                  <EyeIcon
                    color={tokens.colors.neutral[900]}
                    variant={isPasswordVisible ? "open" : "closed"}
                  />
                </div>
              }
              validationOptions={{ required: true }}
              hasError={Boolean(formErrors.password)}
              errorMessage={t("password.error")}
            />
          </div>

          <div className="flex flex-col gap-4">
            <Button
              variant="primary"
              type="submit"
              form="loginForm"
              disabled={isButtonDisabled}
            >
              {t("buttonLabel")}
            </Button>

            <Button variant="link" onClick={handleClickForgotPasswordButton}>
              {t("forgotPasswordLabel")}
            </Button>
          </div>
        </form>
      ) : (
        <div className="h-60 flex items-center">
          <DotLoader />
        </div>
      )}

      {formSubmissionError !== "" && (
        <div className="mt-3">
          <Text color="error">{formSubmissionError}</Text>
        </div>
      )}
      <div id="recaptcha-container" />
    </>
  );
}
