import { useEffect, useState } from "react";
import { ArrowIcon, Button, Text } from "@olivahealth/oli-ui";
import { SSO_MULTI_ORG_LOCAL_STORAGE_KEY } from "@olivahealth/constants";

import useTranslation from "../../../../hooks/useTranslation";
import { SSOOrganisation } from "../../../../hooks/useSSOVerifyEmail";
import EmailForm from "./EmailForm";
import MultiOrgSelect from "./MultiOrgSelect";
import EnforceSSOMessage from "./EnforceSSOMessage";

interface Props {
  isLogin: boolean;
  navigateToAuthPanel: () => void;
  showSSOEnforceMessageInitialValue?: boolean;
  email?: string;
  setEmail: (email: string) => void;
}

export default function SSOPanel({
  isLogin,
  navigateToAuthPanel,
  showSSOEnforceMessageInitialValue = false,
  email,
  setEmail,
}: Props) {
  const { t } = useTranslation("auth", { keyPrefix: "sso" });

  const [enabledSSOOrganisations, setEnabledSSOOrganisations] =
    useState<SSOOrganisation[]>();
  const [showEnforceSSOMessage, setShowEnforceSSOMessage] = useState(
    showSSOEnforceMessageInitialValue,
  );

  // Make sure a previously selected organisation is not stored
  useEffect(() => {
    localStorage.removeItem(SSO_MULTI_ORG_LOCAL_STORAGE_KEY);
  }, []);

  const navigateToEmailForm = () => {
    setEnabledSSOOrganisations([]);
  };

  const handleBackButtonClick = () => {
    if (showEnforceSSOMessage) {
      navigateToAuthPanel();
      return;
    }

    if (enabledSSOOrganisations?.length) {
      navigateToEmailForm();
      return;
    }

    navigateToAuthPanel();
  };

  const renderContent = () => {
    if (showEnforceSSOMessage) {
      return (
        <EnforceSSOMessage
          email={email}
          isLogin={isLogin}
          startSSOLoginFlow={() => setShowEnforceSSOMessage(false)}
        />
      );
    }

    if (enabledSSOOrganisations?.length) {
      return (
        <MultiOrgSelect
          isLogin={isLogin}
          enabledSSOOrganisations={enabledSSOOrganisations}
          email={email}
        />
      );
    }

    return (
      <EmailForm
        isLogin={isLogin}
        setEnabledSSOOrganisations={setEnabledSSOOrganisations}
        setEmail={setEmail}
      />
    );
  };

  return (
    <>
      <span className="flex mb-6 w-full items-center justify-center relative">
        <span className="absolute left-0">
          <Button
            variant="icon"
            aria-label={t("goBackLinkAriaLabel")}
            type="button"
            size="xs"
            onClick={handleBackButtonClick}
          >
            <ArrowIcon direction="left" size={24} />
          </Button>
        </span>
        <Text variant="h3" align="center">
          {t(`${isLogin ? "login" : "signup"}`)}
        </Text>
      </span>
      {renderContent()}
    </>
  );
}
