import ctl from "@netlify/classnames-template-literals";

export const headerContainerClasses = ctl(`
  relative
  mb-5
  flex
  items-center
  w-full
  border-b
  border-gray-300
  pb-4
`);
